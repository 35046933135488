<template>
  <div>
    <!--------------------面包屑导航-------------------->
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item>联系我们</el-breadcrumb-item>
      <el-breadcrumb-item>留言</el-breadcrumb-item>
    </el-breadcrumb>
    <!----------------------卡片区域---------------------->
    <el-card>
      <!--------------------表格--------------------->
      <el-table :data="tableData" stripe border><!--stripe带斑马纹的表格 border边框-->
        <el-table-column type="index" label="序号" width="60px">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 + queryInfo.pageSize * (queryInfo.currentPage - 1) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="phone" label="联系方式"></el-table-column>
        <el-table-column prop="title" label="处理状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.status === '0'" type="danger">未处理</el-tag>
            <el-tag v-if="scope.row.status === '1'">已处理</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="remark" label="处理备注"></el-table-column>
        <el-table-column prop="createTime" label="创建时间"></el-table-column>、
        <el-table-column prop="updateTime" label="最后修改时间"></el-table-column>
        <el-table-column prop="" label="操作">
          <template slot-scope="scope">
            <!--处理-->
            <el-button  v-if="scope.row.status === '0'" type="primary" size="mini" @click="showDealDialog(scope.row)">处理</el-button>
            <!--详情-->
            <el-button type="warning" size="mini" @click="detailItem(scope.row)">详情</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!--------------------分页组件--------------------->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                     :current-page="queryInfo.currentPage" :page-sizes="[10, 20, 30, 40, 50, 100]" :page-size="queryInfo.pageSize"
                     layout="total, sizes, prev, pager, next, jumper" :total="totalCount">
      </el-pagination>
      <!--------------------添加用户对话框--------------------->
      <el-dialog title="处理问题反馈" :visible.sync="dealDialog"
                 width="45%" @close="dealDialogClosed">
        <!--内容主体信息-->
        <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="100px">
          <el-form-item label="描述" prop="remark">
            <el-input v-model="addForm.remark"  type="textarea" :rows="2" placeholder="请输入内容"></el-input>
          </el-form-item>
        </el-form>
        <!--底部区域-->
        <span slot="footer" class="dialog-footer">
        <el-button @click="dealDialog = false">取 消</el-button>
        <el-button type="primary" @click="dealProblem()" :loading="loading">确 定</el-button>
      </span>
      </el-dialog>
      <!--------------------添加用户对话框--------------------->
      <el-dialog title="问题详情" :visible.sync="detailDialogVisible"
                 width="45%" @close="detailDialogClosed">
        <div class="detail">
          <div class="title">{{title}}</div>
          {{content}}
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
export default {
  name: 'News',
  data() {
    return {
      tableData: [],
      queryInfo: {
        currentPage: 1,//当前页码
        pageSize: 10,//每页显示多少条
      },
      totalCount: 0,
      info: {},
      dealDialog: false,//处理对话框
      loading: false,
      detailDialogVisible: false,
      title: '',
      content: '',//详情内容-富文本的
      addForm: {
        remark: ''
      },
      addFormRules: { // 表单校验规则对象
        remark: [
          { required: true, message: '请输入描述', trigger: 'blur' }
        ]
      },
    }
  },
  mounted () {
    this.getList();
  },
  methods: {
    async detailItem(row) {
      const { data: res } = await this.$http.get('/feeback/detail', { params: { id: row.id }})
      this.loading = false;
      if (res.code !== 200) {
        this.$message.error(res.message)
      } else {
        this.title = res.result.title
        this.content = res.result.content
      }
      this.detailDialogVisible = true
    },
    detailDialogClosed() {
      this.detailDialogVisible = false
    },
    dealProblem() {
      this.$refs.addFormRef.validate(async valid => {
        if (valid) {
          this.loading = true;
          let param ={
            id: this.info.id,
            remark: this.addForm.remark
          }
          const { data: res } = await this.$http.put('/feeback/updateStatus', param)
          this.loading = false;
          if (res.code !== 200) {
            this.$message.error(res.message)
          } else {
            this.dealDialog = false
            await this.getList()
            this.$message.success(res.message)
          }
        } else {
          //this.$message.error('数据校验不通过!')
        }
      })
    },
    dealDialogClosed() {
      this.info = {}
    },
    //打开处理对话框
    showDealDialog(rows) {
      this.info = rows
      this.dealDialog = true
    },
    async getList() {
      const { data: res } = await this.$http.post('/feeback/list', this.queryInfo)
      if (res.code !== 200) {
        this.$message.error(res.message)
        this.dealDialog = false
      } else {
        this.tableData = res.result.items
        this.totalCount = res.result.totalCount
      }
    },
    // 监听每页显示多少条的改变
    handleSizeChange (newSize) {
      this.queryInfo.pageSize = newSize
      this.getList()
    },
    // 监听选择第了几页
    handleCurrentChange (newPage) {
      this.queryInfo.currentPage = newPage
      this.getList()
    },
  }
}
</script>

<style scoped>
.el-pagination {
  margin-top: 15px;
}
.detail {
  min-height: 400px;
}
.title {
  color: #333;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}
</style>
